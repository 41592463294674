.modal {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.4);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    /*align-items: center;*/
    justify-content: center;
    transition: 0.5s;
    opacity: 1;
    pointer-events: all;
    z-index: 20;
    overflow-y: auto;
}

.modal__content {
    top: calc(10%);
    position: absolute;
    border-radius: 12px;
    background-color: white;
    transition: 0.4s all;
    transform: scale(1);
    width: max-content;
    height: max-content;
    display: block;
}
.icon_close {
    position: absolute;
    top: 20px;
    right: 24px;
    cursor: pointer;
}
