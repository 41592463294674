@tailwind base;
@tailwind components;
@tailwind utilities;

.app {
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    grid-template-areas: 'header' 'main' 'footer';
}
.app > main {
    grid-area: main;
}

.app > footer {
    /*margin-top: 100px;*/
    grid-area: footer;
    /*background: blue;*/
    background: rgba(0, 0, 0, .66);
    /*color: red;*/
    display: flex;
    justify-content: space-between;
}